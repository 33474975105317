import { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import moment from "moment";
import mainStore from "../../store/main";
import mixpanel from "mixpanel-browser";
mixpanel.init("6a65c67689e1f7382d034cc2077dde08");

const Drawer = () => {
  const {
    openDrawer,
    setDrawer,
    quantity,
    setQuantity,
    isUpgrade,
    setIsUpgrade,
    time,
  } = mainStore();
  const { timer } = mainStore();

  const [loading, setLoading] = useState(false);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [continueToCheckoutLoading, setContinueToCheckoutLoading] =
    useState(false);
  const [upgradeModalLoader, setUpgradeModalLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [dealActualPrice, setDealActualPrice] = useState(79);
  const [dealPrice, setDealPrice] = useState(29.95);
  const [showUpgradeDeal, setShowUpgradeDeal] = useState("");
  const [shipingFulfillment, setShippingFulfillment] = useState(true);
  const [selectedFirness, setSelectedFirmness] = useState("medium/firm");

  // const queenSizeVariant = "49345725006139";
  // const kingSizeVariant = "49346363785531";
  // const freePillowVariantQueen = "49345760002363";
  // const freePillowVariantKing = "49371815510331";

  const [queenSizeVariant, setQueenSizeVariant] = useState("51788861833588");
  const [kingSizeVariant, setKingSizeVariant] = useState("51788878807412");
  const [freePillowVariantQueen, setFreePillowVariantQueen] =
    useState("51788891357556");
  const [freePillowVariantKing, setFreePillowVariantKing] =
    useState("51788892307828");

  const mysteryGiftVariant = "49349250482491";
  const shippingInsuranceVariant = "51694832255348";

  let currentDate = moment();

  // Calculate the next business day
  while (currentDate.day() === 6 || currentDate.day() === 0) {
    currentDate.add(1, "day");
  }

  let oneWeekAhead = currentDate.add(1, "day");

  // Adjust again if the new date falls on a weekend
  while (oneWeekAhead.day() === 6 || oneWeekAhead.day() === 0) {
    oneWeekAhead.add(1, "day");
  }
  let shipsByDate = oneWeekAhead.format("ddd, MMM Do");
  let freeGiftsDate = moment().format("MMM Do");

  useEffect(() => {
    resetLoadings();
  }, []);

  useEffect(() => {
    if (openDrawer) {
      document.body.classList.add("overflow_body");
    } else {
      document.body.classList.remove("overflow_body");
    }
  }, [openDrawer]);

  useEffect(() => {
    if (!isUpgrade) {
      setDealActualPrice(79);
      setDealPrice(29.95);
    } else {
      setDealActualPrice(99);
      setDealPrice("39.90");
    }
  }, [isUpgrade]);

  useEffect(() => {
    if (selectedFirness === "medium/firm") {
      setQueenSizeVariant("51788861833588");
      setKingSizeVariant("51788878807412");
      setFreePillowVariantQueen("51788891357556");
      setFreePillowVariantKing("51788892307828");
    } else {
      setQueenSizeVariant("49345725006139");
      setKingSizeVariant("49346363785531");
      setFreePillowVariantQueen("49345760002363");
      setFreePillowVariantKing("49371815510331");
    }
  }, [selectedFirness]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    return formattedTime;
  };

  const onUpgrade = () => {
    setIsUpgrade(!isUpgrade);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPhone|iPad|iPod/i.test(userAgent);
  };

  const onHandleChange = (counter) => {
    if (
      (counter === "decrease" && quantity <= 1) ||
      (counter === "increase" && quantity >= 3)
    ) {
      return;
    }

    const count = counter === "increase" ? quantity + 1 : quantity - 1;
    setQuantity(count);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const proceedToCheckout = () => {
    if (quantity === 1 || !isUpgrade) {
      if (quantity >= 2) {
        setShowUpgradeDeal("upgradeToKing");
      } else {
        setShowUpgradeDeal("specialDeal");
      }
      setOpenModal(true);
    } else {
      checkout(true);
    }
  };

  const proceedFromUpgradeModal = (upgrade, param) => {
    if (!upgrade) {
      checkout(false, param);
    } else if (param === "acceptUpgradeOffer") {
      setIsUpgrade(true);
      checkout(true, param);
    } else if (param === "acceptSpecialOffer") {
      setQuantity(2);
      checkout(isUpgrade, param, 2);
    } else if (param === "rejectSpecialOffer") {
      checkout(isUpgrade, param);
    }
  };

  const checkout = async (upgrade, param, upgradedQuantity) => {
    if (param === "acceptUpgradeOffer") {
      mixpanel.track("Pop Deal Accepted", {
        deal_name: "king-upgrade",
      });
    } else if (param === "acceptSpecialOffer") {
      mixpanel.track("Pop Deal Accepted", {
        deal_name: "extra-pillow",
      });
    }

    if (upgrade) {
      mixpanel.track("Pop Deal Accepted", {
        deal_name: "Upgrade to King",
      });
    }

    const lineItems = [
      {
        variantId: `gid://shopify/ProductVariant/${
          upgrade ? kingSizeVariant : queenSizeVariant
        }`,
        quantity: upgradedQuantity ? upgradedQuantity : quantity,
      },
    ];

    if (quantity >= 3) {
      lineItems.push({
        variantId: `gid://shopify/ProductVariant/${
          upgrade ? freePillowVariantKing : freePillowVariantQueen
        }`,
        quantity: 1,
      });
      lineItems.push({
        variantId: `gid://shopify/ProductVariant/${mysteryGiftVariant}`,
        quantity: 1,
      });
    }

    if (shipingFulfillment) {
      lineItems.push({
        variantId: `gid://shopify/ProductVariant/${shippingInsuranceVariant}`,
        quantity: 1,
      });
    }

    const createCheckoutQuery = `
          mutation {
            checkoutCreate(input: {
              lineItems: [
                ${lineItems
                  .map(
                    (item) => `
                {
                  variantId: "${item.variantId}",
                  quantity: ${item.quantity}
                }`
                  )
                  .join(",")}
              ]
            }) {
              checkout {
                id
                webUrl
              }
              userErrors {
                field
                message
              }
            }
          }
        `;

    if (!param) {
      setLoading(true);
    } else if (
      param === "acceptUpgradeOffer" ||
      param === "acceptSpecialOffer"
    ) {
      setUpgradeLoading(true);
    } else if (
      param === "rejectUpgradeOffer" ||
      param === "rejectSpecialOffer"
    ) {
      setContinueToCheckoutLoading(true);
    } else if (param === "modalClose") {
      setUpgradeModalLoader(true);
    }

    try {
      const response = await axios.post(
        "https://secure.getfelo.com/api/2023-07/graphql.json",
        { query: createCheckoutQuery },
        {
          headers: {
            "X-Shopify-Storefront-Access-Token":
              "16cdda8ce0fae6ef8f78fe27d99f64e2",
            "Content-Type": "application/json",
          },
        }
      );

      const checkout = response.data.data.checkoutCreate.checkout;

      if (checkout) {
        // Apply the first discount code
        const applyFirstDiscountCodeQuery = `
              mutation {
                checkoutDiscountCodeApplyV2(
                  discountCode: "${upgrade ? "62% OFF" : "62% OFF!"}",
                  checkoutId: "${checkout.id}"
                ) {
                  checkout {
                    id
                    webUrl
                  }
                  userErrors {
                    field
                    message
                  }
                }
              }
            `;

        const firstDiscountResponse = await axios.post(
          "https://secure.getfelo.com/api/2023-07/graphql.json",
          { query: applyFirstDiscountCodeQuery },
          {
            headers: {
              "X-Shopify-Storefront-Access-Token":
                "16cdda8ce0fae6ef8f78fe27d99f64e2",
              "Content-Type": "application/json",
            },
          }
        );

        const firstDiscountCheckout =
          firstDiscountResponse.data.data.checkoutDiscountCodeApplyV2.checkout;

        if (firstDiscountCheckout) {
          // Apply the second discount code
          const applySecondDiscountCodeQuery = `
                mutation {
                  checkoutDiscountCodeApplyV2(
                    discountCode: "GIFT",
                    checkoutId: "${firstDiscountCheckout.id}"
                  ) {
                    checkout {
                      id
                      webUrl
                    }
                    userErrors {
                      field
                      message
                    }
                  }
                }
              `;

          const secondDiscountResponse = await axios.post(
            "https://secure.getfelo.com/api/2023-07/graphql.json",
            { query: applySecondDiscountCodeQuery },
            {
              headers: {
                "X-Shopify-Storefront-Access-Token":
                  "16cdda8ce0fae6ef8f78fe27d99f64e2",
                "Content-Type": "application/json",
              },
            }
          );

          const secondDiscountCheckout =
            secondDiscountResponse.data.data.checkoutDiscountCodeApplyV2
              .checkout;

          if (secondDiscountCheckout) {
            setTimeout(() => {
              resetLoadings();
            }, 1000);
            window.location.href = secondDiscountCheckout.webUrl;
          } else {
            resetLoadings();
            console.error(
              "User errors:",
              secondDiscountResponse.data.data.checkoutDiscountCodeApplyV2
                .userErrors
            );
          }
        } else {
          resetLoadings();
          console.error(
            "User errors:",
            firstDiscountResponse.data.data.checkoutDiscountCodeApplyV2
              .userErrors
          );
        }
      } else {
        resetLoadings();
        console.error(
          "User errors:",
          response.data.data.checkoutCreate.userErrors
        );
      }
    } catch (error) {
      resetLoadings();
      console.error("Error creating checkout:", error);
    }
  };

  const resetLoadings = () => {
    onCloseModal();
    setLoading(false);
    setUpgradeLoading(false);
    setContinueToCheckoutLoading(false);
    setUpgradeModalLoader(false);
  };

  return (
    <div>
      <div
        id="cart-overlay"
        className={`cart-overlay ${openDrawer ? "cart-overlay-bg " : ""}`}
      ></div>
      <article class="drawer-holder_wrapper">
        <div class="drawer_inside"></div>
        <div
          data-drawer="drawer"
          class={`drawer_inside-box ${openDrawer ? "is-active" : ""}`}
        >
          <div id="checkout-maininfo" class="drawer_inside-inner">
            <div id="checkout-info" class="drawer_inside-content">
              <div class="new-drawer_head-block">
                <div class="cart-head_back-text">Your Cart</div>
                <a
                  id="close-drawer"
                  href="#"
                  class="cart-head_back-block w-inline-block"
                  onClick={() => setDrawer(false)}
                >
                  <div class="cart-back-icon w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewbox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>
                </a>
              </div>
              <div class="offer_rounded-wrapper">
                <h1 class="offer_rounded-heading">
                  🎉 VIP ACCESS - WINTER SALE ❄️
                </h1>
              </div>
              <div class="new-drawer_gift-block">
                <div class="cart-head_inner-block">
                  <div class="cart-head_rounded">
                    <div class="cart-head_title">Unlock Free Gifts!</div>
                  </div>
                  <div id="cart_heading_text" class="cart-head_heading">
                    {quantity === 1 ? (
                      <>
                        <strong>Add a 2nd</strong> Pillow, Get{" "}
                        <span class="change-box">FREE Shipping</span>
                      </>
                    ) : quantity === 2 ? (
                      <>
                        <strong>Add a 3rd</strong> Pillow, Get The{" "}
                        <span class="change-box">4th FREE</span>
                      </>
                    ) : (
                      <>
                        <strong>Congrats, All Gifts Are Unlocked!</strong>
                      </>
                    )}
                  </div>
                </div>
                <div class="cart-head_gift-blocks">
                  <div data-gift="container" class="gift-blocks new-look">
                    <div data-gift="1" class="gift_card is-active">
                      <div class="free_offer-badge">
                        <div class="free_offer-text">62% OFF</div>
                      </div>
                      <img
                        id="gift1image"
                        loading="eager"
                        alt=""
                        src="images/fireworks.svg"
                        class="gift_image"
                        height={40}
                      />
                      <div
                        data-bundle-gift="1"
                        class="gift-overlay first"
                      ></div>
                      <div class="gift-locker first">
                        <img
                          src="images/lock-new.svg"
                          loading="lazy"
                          alt=""
                          class="gift-lockings"
                        />
                      </div>
                      <div
                        data-bundle-gift="2"
                        class="gift-checkmark is-active w-embed"
                      >
                        <svg
                          width="420"
                          height="420"
                          viewbox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div
                      data-gift="2"
                      class={`gift_card ${quantity >= 2 ? "is-active" : ""}`}
                    >
                      <div class="free_offer-badge">
                        <div class="free_offer-text">
                          FREE <span class="text-span-171">$8.99</span>
                        </div>
                      </div>
                      <img
                        id="gift1image"
                        loading="eager"
                        alt=""
                        src="images/shipping_truck.svg"
                        class="gift_image"
                        height={40}
                      />
                      <div
                        data-bundle-gift="2"
                        class="gift-overlay second"
                      ></div>
                      <div class="gift-locker second">
                        <img
                          src="images/lock-new.svg"
                          loading="lazy"
                          alt=""
                          class="gift-lockings"
                        />
                      </div>
                      <div
                        data-bundle-gift="3"
                        class="gift-checkmark is-active w-embed"
                      >
                        <svg
                          width="420"
                          height="420"
                          viewbox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div
                      data-gift="3"
                      class={`gift_card ${quantity >= 3 ? "is-active" : ""}`}
                    >
                      <div class="free_offer-badge">
                        <div class="free_offer-text">
                          FREE <span class="text-span-172">$19.99</span>
                        </div>
                      </div>
                      <img
                        id="gift1image"
                        loading="eager"
                        alt=""
                        src="images/Small-03.svg"
                        class="gift_image"
                        height="34px"
                      />
                      <div
                        data-bundle-gift="3"
                        class="gift-overlay third"
                      ></div>
                      <div class="gift-locker third">
                        <img
                          src="images/lock-new.svg"
                          loading="lazy"
                          alt=""
                          class="gift-lockings"
                        />
                      </div>
                      <div
                        data-bundle-gift="3"
                        class="gift-checkmark is-active w-embed"
                      >
                        <svg
                          width="420"
                          height="420"
                          viewbox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div
                      data-gift="4"
                      class={`gift_card ${quantity >= 3 ? "is-active" : ""}`}
                    >
                      <div class="free_offer-badge">
                        <div class="free_offer-text">
                          FREE <span class="text-span-173">$79.99</span>
                        </div>
                      </div>
                      <img
                        sizes="(max-width: 479px) 19vw, (max-width: 991px) 47px, (max-width: 1439px) 50px, (max-width: 1919px) 3vw, 50px"
                        srcset="images/02-p-500.png 500w, images/02.png 550w"
                        alt=""
                        src="images/02.png"
                        loading="eager"
                        id="gift1image"
                        class="gift_image"
                        height={40}
                        width={40}
                      />
                      <div
                        data-bundle-gift="4"
                        class="gift-overlay forth"
                      ></div>
                      <div class="gift-locker forth">
                        <img
                          src="images/lock-new.svg"
                          loading="lazy"
                          alt=""
                          class="gift-lockings"
                        />
                      </div>
                      <div
                        data-bundle-gift="3"
                        class="gift-checkmark is-active w-embed"
                      >
                        <svg
                          width="420"
                          height="420"
                          viewbox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div data-gift="titles" class="gift-titles">
                    <div data-gift="1" class="gift_title is-active">
                      <div class="gift-title_text">Labor Day Sale</div>
                    </div>
                    <div data-gift="2" class="gift_title is-active">
                      <div class="gift-title_text">Free Shipping</div>
                    </div>
                    <div data-gift="3" class="gift_title  ">
                      <div class="gift-title_text">Mystery Gift</div>
                    </div>
                    <div data-gift="4" class="gift_title">
                      <div class="gift-title_text">Free Pillow</div>
                    </div>
                  </div>
                </div>
                <div class="w-embed"></div>
              </div>
              <div class="cart-item_block">
                <div data-flavor="original" class="cart_line-item">
                  <div class="cart_card-wrapper">
                    <div class="pillow-image_block">
                      <img
                        src="images/fire-icon.svg"
                        loading="lazy"
                        alt=""
                        class="fire-svg"
                      />
                      <div class="verticle-badge">
                        <div class="text-block-227">SOLD OUT 3X</div>
                      </div>
                      <img
                        width="280"
                        height="Auto"
                        alt=""
                        src="https://uploads-ssl.webflow.com/5f2a8f397e6634dbde061f19/66b51b47b39fc548fd2869ae_felo-latest-pillow-reduced.jpg"
                        loading="eager"
                        class="pillow_image"
                      />
                    </div>
                    <div class="cart_card-info">
                      <div class="cart_content">
                        <div class="cart_description">
                          <div class="cart_item-name">
                            Felo Pillow - {isUpgrade ? "King" : "Queen"} Size x
                            {quantity}
                          </div>
                          <div class="cart_pricing-block">
                            <div
                              id="each_price_cart_free"
                              class="sum-price reduced old-price-txt hidden_attribute is-left"
                            >
                              ${dealActualPrice}/ea
                            </div>
                            <div id="each_price_cart" class="sum-price reduced">
                              ${dealPrice}/ea
                            </div>
                          </div>
                        </div>
                        <div class="cart_quantity-block">
                          <div class="cart_quantity-container">
                            <a
                              href="#"
                              class="selector_decrease-block w-inline-block"
                              onClick={() => onHandleChange("decrease")}
                            >
                              <div class="selector_minus-inner">
                                <div class="blue_minus-svg w-embed">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    fill="currentColor"
                                    class="bi bi-dash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"></path>
                                  </svg>
                                </div>
                              </div>
                            </a>
                            <div class="qty_selector-number">
                              <div class="qty_selector-text"> {quantity}</div>
                            </div>
                            <a
                              href="#"
                              class="selector_increase-block w-inline-block"
                              onClick={() => onHandleChange("increase")}
                            >
                              <div class="selector_plus-inner">
                                <div class="blue_plus-svg w-embed">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    fill="currentColor"
                                    class="bi bi-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"></path>
                                  </svg>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {quantity >= 3 && (
                  <div data-flavor="original" class="cart_line-item">
                    <div class="cart_card-wrapper">
                      <div class="pillow-image_block">
                        {/* <img
                          src="images/fire-icon.svg"
                          loading="lazy"
                          alt=""
                          class="fire-svg"
                        />
                        <div class="verticle-badge">
                          <div class="text-block-227">SOLD OUT 3X</div>
                        </div> */}
                        <img
                          width="280"
                          height="Auto"
                          alt=""
                          src="https://uploads-ssl.webflow.com/5f2a8f397e6634dbde061f19/66b51b47b39fc548fd2869ae_felo-latest-pillow-reduced.jpg"
                          loading="eager"
                          class="pillow_image"
                        />
                      </div>
                      <div class="cart_card-info">
                        <div>
                          <div class="cart_description">
                            <div class="cart_item-name">
                              Felo Pillow - {isUpgrade ? "King" : "Queen"} Size
                              x1
                            </div>
                            <div class="cart_pricing-block">
                              <div
                                id="each_price_cart_free"
                                class="sum-price reduced old-price-txt hidden_attribute is-left"
                              >
                                ${dealActualPrice}/ea
                              </div>
                              <div
                                id="each_price_cart"
                                class="sum-price reduced free"
                              >
                                FREE
                              </div>
                            </div>
                          </div>
                          <div id="lowStock" className="ships-by-box">
                            <div className="cart_quantity-block"></div>
                            <div className="ring-container w-embed">
                              <div className="ringring"></div>
                              <div className="circle"></div>
                            </div>
                            <div id="order-message" className="order-by-text">
                              Order by {freeGiftsDate}, for guaranteed FREE
                              Gifts
                            </div>
                          </div>
                          <div className="cart_badge-block"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div class="cart_line-selection">
                  <div class="cl_sel-title">Select Your Firmness:</div>
                  <div class="selection_main-box">
                    <div
                      data-current="Price Tier 1"
                      data-easing="ease"
                      data-duration-in="0"
                      data-duration-out="0"
                      class="selection_tabs w-tabs"
                    >
                      <div class="sel_tabs-content w-tab-content">
                        <div
                          data-w-tab="Price Tier 1"
                          class="tab-content w-tab-pane w--tab-active"
                        ></div>
                        <div
                          data-w-tab="Price Tier 2"
                          class="tab-content w-tab-pane"
                        ></div>
                      </div>
                      <div class="sel_tabs-menu w-tab-menu">
                        <a
                          data-w-tab="Price Tier 1"
                          class={`checkout-select-variant-2 _4_bottles gonew checkout-select-variant wfs zx1 w-inline-block w-tab-link ${
                            selectedFirness === "medium/firm"
                              ? "w--current"
                              : ""
                          }`}
                          onClick={() => setSelectedFirmness("medium/firm")}
                        >
                          <div
                            data-scroll-time="1.25"
                            class="select-box-div top1 ff1 da1"
                          >
                            <div class="checkbox-radio">
                              <div class="check-circle-new sq1 chc1">
                                {selectedFirness === "medium/firm" && (
                                  <img
                                    loading="lazy"
                                    src="images/gg2-mark.svg"
                                    alt=""
                                    class="check-white-6"
                                  />
                                )}
                              </div>
                            </div>
                            <div class="sel-top-box">
                              <div class="cl_check-wrapper">
                                <div class="cl_checkbox-label">Medium/Firm</div>
                                <div class="cl_checkbox-label-sub">
                                  Best for Side Sleepers
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          data-w-tab="Price Tier 2"
                          class={`checkout-select-variant-2 _4_bottles gonew checkout-select-variant wfs zx1 w-inline-block w-tab-link ${
                            selectedFirness === "soft/medium"
                              ? "w--current"
                              : ""
                          }`}
                          onClick={() => setSelectedFirmness("soft/medium")}
                        >
                          <div
                            data-scroll-time="1.25"
                            class="select-box-div ff2 da2"
                          >
                            <div class="checkbox-radio">
                              <div class="check-circle-new sq1 chc1">
                                {selectedFirness === "soft/medium" && (
                                  <img
                                    loading="lazy"
                                    src="images/gg2-mark.svg"
                                    alt=""
                                    class="check-white-6"
                                  />
                                )}
                              </div>
                            </div>
                            <div class="sel-top-box">
                              <div class="cl_check-wrapper">
                                <div class="cl_checkbox-label">Soft/Medium</div>
                                <div class="cl_checkbox-label-sub">
                                  For Back and Combination Sleepers
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="upgrade_wrapper-new">
                  <div class="uw_left">
                    <div class="uw_one">
                      <img
                        src="images/fil-pil-ho.png"
                        loading="lazy"
                        width="94"
                        alt=""
                        class="uw_upgrade-product"
                      />
                    </div>
                    <div class="uw_two">
                      <div class="uw_title">Upgrade to King Size</div>
                      {/* <div class="uw_star-block">
                        <img
                          src="images/stars-blue.svg"
                          loading="lazy"
                          alt=""
                          class="uw_star-icon"
                        />
                        <div class="uw_trust-review">Lamaagto</div>
                      </div> */}
                      <div class="uw_subtitle">Only $9.99 Extra per Pillow</div>
                    </div>
                  </div>
                  <div class="uw_right">
                    <div class="uw_three">
                      <a
                        href="#"
                        class={`uw_upgrade-btn w-inline-block ${
                          isUpgrade ? "active" : ""
                        }`}
                        onClick={() => onUpgrade()}
                      >
                        <img
                          src="images/up-arrow-btn.svg"
                          loading="lazy"
                          alt=""
                          class="uw_btn-icon"
                        />
                        <div class="uw_btn-text">
                          {isUpgrade ? "UPGRADED" : "UPGRADE"}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                {isMobileDevice() && <div className="h80"></div>}
              </div>
            </div>
            <div class="new-drawer_footer">
              <div class="ck_new-badge-block">
                <div class="co_left-block">
                  <img
                    loading="lazy"
                    src="images/small-fire.svg"
                    alt=""
                    class="fire-badge-icon"
                  />
                  <div class="ck_left-badge-text">
                    <strong class="bold-text-38">Hurry! 126 people</strong>
                    are on checkout
                  </div>
                </div>
                <div class="co_right-block">
                  <img
                    loading="lazy"
                    src="images/box-fast.svg"
                    alt=""
                    class="fast-box-icon"
                  />
                  <div class="ck-badge_right-text">Ships by  </div>
                  <div class="ck_badge_right-date">{shipsByDate}</div>
                </div>
              </div>
              <button
                className={`pushable w100p ${loading ? "pay-now-bg" : ""}`}
                data-target="drawer"
                href="#"
                disabled={loading}
                onClick={proceedToCheckout}
              >
                <div className="front">
                  {loading ? (
                    <div className="ck_button-text">
                      <Oval
                        height="46"
                        width="46"
                        color="#ffffff"
                        secondaryColor="#ffffff"
                        ariaLabel="oval-loading"
                        wrapperClass="loader-wrapper"
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="ck-countdown-text">
                        Sale Ends In <span id="mobile_timer">{timer}</span>
                      </div>
                      <div className="ck_button-text">
                        <strong> Proceed to Checkout</strong>{" "}
                      </div>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </article>

      {openModal && (
        <div className="modal-overlay">
          <div>
            <div class="pop-up_flex-wrapper">
              {upgradeModalLoader && (
                <div className="upgrade-modal-overlay">
                  <Oval
                    height="60"
                    width="60"
                    color="#ffffff"
                    secondaryColor="#ffffff"
                    ariaLabel="oval-loading"
                    wrapperclassName="loader-wrapper"
                  />
                </div>
              )}
              {showUpgradeDeal === "upgradeToKing" && (
                <div class="upgrade_pop-secondary">
                  <div class="pop-up_body-inner">
                    {/* <h1 class="pu-body_heading secondary">Hold On!</h1> */}
                    <div class="post_up-image-box no-bg">
                      <img
                        src="images/big-pillow-bb.png"
                        loading="lazy"
                        sizes="100vw"
                        srcset="
                        images/big-pillow-bb-p-500.png 500w,
                        images/big-pillow-bb.png       750w
                      "
                        alt=""
                        class="post_product-two"
                      />
                      <img
                        src="images/big-glow.png"
                        loading="lazy"
                        sizes="100vw"
                        srcset="
                        images/big-glow-p-500.png 500w,
                        images/big-glow.png       680w
                      "
                        alt=""
                        class="glow-png other"
                      />
                    </div>
                    <div class="post_up-content-box">
                      <div class="cen-title-upgrade-box">
                        <div class="cu1">
                          <h1 class="pu-body_sub-heading">
                            <span class="text-span-174">🌟</span>
                          </h1>
                        </div>
                        <div class="cu2">
                          <h1 class="pu-body_sub-heading">
                            Last Chance
                            <br />
                            Special Offer!
                          </h1>
                        </div>
                        <div class="cu3">
                          <h1 class="pu-body_sub-heading">
                            <span class="text-span-175">🌟</span>
                          </h1>
                        </div>
                      </div>
                      <h1 class="pu-body_offer-text">
                        Here&#x27;s
                        <strong>
                          <em class="italic-text-2">Your Last</em>
                        </strong>{" "}
                        chance
                        <br />
                        to upgrade{" "}
                        <strong>
                          <em class="italic-text-3">to King</em>
                        </strong>
                      </h1>
                      <h1 class="pu-body_offer-text">
                        For{" "}
                        <strong>
                          <em class="italic-text-4">Just</em>
                        </strong>{" "}
                        <strong>
                          <em class="italic-text-6">$19.99</em>
                        </strong>{" "}
                        <strong>
                          <em class="italic-text-5">$9.99</em>
                        </strong>{" "}
                        Per Pillow
                      </h1>
                    </div>
                    <div class="pop-up_footer">
                      <button
                        type="button"
                        class="pu_upgrade-btn w-inline-block"
                        onClick={() => {
                          proceedFromUpgradeModal(true, "acceptUpgradeOffer");
                        }}
                        disabled={upgradeLoading || continueToCheckoutLoading}
                      >
                        <img
                          src="images/up-arrow-btn.svg"
                          loading="lazy"
                          alt=""
                          class="upgrade-icon-svg"
                        />
                        <div class="pu_upgrade-btn-text">
                          {upgradeLoading ? (
                            <Oval
                              height="25"
                              width="25"
                              color="#ffffff"
                              secondaryColor="ffffff"
                              ariaLabel="oval-loading"
                              wrapperClass="loader-wrapper"
                            />
                          ) : (
                            "YES, UPGRADE!"
                          )}
                        </div>
                      </button>
                      <button
                        type="button"
                        class="pu_continue-btn w-inline-block"
                        onClick={() => {
                          proceedFromUpgradeModal(
                            isUpgrade,
                            "rejectUpgradeOffer"
                          );
                        }}
                        disabled={upgradeLoading || continueToCheckoutLoading}
                      >
                        <div class="pu_continue-btn-text">
                          {continueToCheckoutLoading ? (
                            <Oval
                              height="25"
                              width="25"
                              color="white"
                              secondaryColor="white"
                              ariaLabel="oval-loading"
                              wrapperClass="loader-wrapper"
                            />
                          ) : (
                            "No Thanks, Proceed to Checkout"
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showUpgradeDeal === "specialDeal" && (
                <div class="upgrade_pop-primary">
                  <div class="pop-up_body-inner">
                    <h1 class="pu-body_heading">Hold On!</h1>
                    <div class="post_up-image-box">
                      <img
                        src="images/pillow-glow.png"
                        loading="lazy"
                        sizes="100vw"
                        srcset="
                            images/pillow-glow-p-500.png 500w,
                            images/pillow-glow.png       540w
                          "
                        alt=""
                        class="post_product-one"
                      />
                      <img
                        src="images/glowing.png"
                        loading="lazy"
                        sizes="100vw"
                        srcset="
                            images/glowing-p-500.png 500w,
                            images/glowing.png       551w
                          "
                        alt=""
                        class="glow-png"
                      />
                    </div>
                    <div class="post_up-content-box">
                      <div class="cen-title-upgrade-box">
                        <div class="cu1">
                          <h1 class="pu-body_sub-heading">
                            <span class="text-span-174">🌟</span>
                          </h1>
                        </div>
                        <div class="cu2">
                          <h1 class="pu-body_sub-heading">Special Offer!</h1>
                        </div>
                        <div class="cu3">
                          <h1 class="pu-body_sub-heading">
                            <span class="text-span-175">🌟</span>
                          </h1>
                        </div>
                      </div>
                      <h1 class="pu-body_offer-text">
                        Add a{" "}
                        <strong class="bold-text-39">
                          2nd Pillow
                          <br />‍
                        </strong>
                        for <strong class="bold-text-40">just $20</strong> more
                      </h1>
                      <h1 class="pu-body_offer-text">
                        Plus{" "}
                        <strong>
                          <em class="italic-text">Get FREE Shipping</em>
                        </strong>
                        <br />
                        on your entire order
                      </h1>
                    </div>
                    <div class="pop-up_footer">
                      <button
                        type="button"
                        class="pu_upgrade-btn w-inline-block"
                        onClick={() => {
                          proceedFromUpgradeModal(true, "acceptSpecialOffer");
                        }}
                        disabled={upgradeLoading || continueToCheckoutLoading}
                      >
                        <img
                          src="images/up-arrow-btn.svg"
                          loading="lazy"
                          alt=""
                          class="upgrade-icon-svg"
                        />
                        <div class="pu_upgrade-btn-text">
                          {upgradeLoading ? (
                            <Oval
                              height="25"
                              width="25"
                              color="#ffffff"
                              secondaryColor="ffffff"
                              ariaLabel="oval-loading"
                              wrapperClass="loader-wrapper"
                            />
                          ) : (
                            "YES, UPGRADE!"
                          )}
                        </div>
                      </button>
                      <button
                        type="button"
                        class="pu_continue-btn w-inline-block"
                        onClick={() => {
                          proceedFromUpgradeModal(
                            isUpgrade,
                            "rejectSpecialOffer"
                          );
                        }}
                        disabled={upgradeLoading || continueToCheckoutLoading}
                      >
                        <div class="pu_continue-btn-text">
                          {continueToCheckoutLoading ? (
                            <Oval
                              height="25"
                              width="25"
                              color="white"
                              secondaryColor="white"
                              ariaLabel="oval-loading"
                              wrapperClass="loader-wrapper"
                            />
                          ) : (
                            "No Thanks, Proceed to Checkout"
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Drawer;
